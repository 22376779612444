@import '../../constants/constants.css';

.book-header-block {
  background-color: var(--base-color-blue);
  color: #ffffff !important;
  padding: 50px;
  font-size: 20px;
}

.back-button-style {
  cursor: pointer;
  position: relative;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.back-button-style .anticon {
  margin-right: 10px;
}

.back-button-style:hover {
  color: var(--base-color-orange);
}

.book-header-block .items-date-block:before {
  background: url("../../assets/img/icon_time_white.svg") center no-repeat;
}

.book-header-block .items-direction:before {
  background: url("../../assets/img/bus_white.svg") center no-repeat;
}

.book-header-block .results-items .anticon {
  border: 2px solid #ffffff;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  bottom: 32px;
  left: -35px
}

.book-header-block .ant-statistic * {
  color: #ffffff !important;
}

.book-header-block .ant-statistic-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.book-header-block .ant-statistic-content {
  font-size: 26px;
  font-weight: 700;
}

.book-header-block .destination-result {
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 20px !important;
  height: 100%;
}

.book-header-block .ant-statistic {
  position: relative;
  min-width: 170px;
  text-align: center;
}

.book-header-block .ant-statistic:before {
  content: '';
  width: 50px;
  height: 50px;
  left: -45px;
  bottom: 10px;
  position: absolute;
  display: inline-block;
  background: url("../../assets/img/icon_timer.svg") center no-repeat;
}

.bus-floor-type {
  text-transform: uppercase;
  text-align: center;
  color: var(--base-color-blue);
  margin-bottom: 30px;
}

.seats-color-legend .legend-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 30px;
}

.legend-available,
.legend-selected,
.legend-booked {
  position: relative;
  margin-right: 40px;
  display: flex;
  align-items: center;
}

.legend-booked {
  margin-right: 0 !important;
}

.legend-available:before,
.legend-selected:before,
.legend-booked:before {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border: 1px solid #000000;
  border-radius: 7px;
  position: relative;
}

.legend-available:before {
  background-color: #F5F6FC;
}

.legend-selected:before {
  background-color: var(--base-color-orange);
}

.legend-booked:before {
  background-color: #316BBF;
}

.cart-seat-info {
  min-height: 200px;
  padding: 20px;
  background-color: #f0f0f5;
}

.cart-seat-info h5 {
  margin-bottom: 20px;
}

.cart-seat-info h4 {
  font-size: 1.375rem;
}

.total-ticket-price {
  padding: 0px 20px 10px;
}

.total-ticket-price h3 {
  font-weight: 700;
  border-top: 2px solid #DCDCE3;
  padding-top: 30px;
  font-size: 1.5rem;
}

.cart-next-btn {
  padding: 10px 20px 20px;
}

.cart-next-btn .book-now-button {
  width: 100%;
  background-color: var(--base-color-orange);
  color: #ffffff;
  font-size: 1.25rem;
  height: inherit;
  border: none;
  padding: 10px;
  border-radius: 30px;
  font-weight: 700;
}

.cart-next-btn .book-now-button:hover {
  background-color: var(--base-color-blue)
}

@keyframes shake {
  0% {
      transform: rotate(5deg);
  }

  25% {
      transform: rotate(-6deg);
  }

  50% {
      transform: rotate(5deg);
  }

  75% {
      transform: rotate(-6deg);
  }

  100% {
      transform: rotate(0deg);
  }
}

.book-trigger-animation {
  color: red;
  animation: shake 0.3s cubic-bezier(.36, .07, .19, .97) both;
  backface-visibility: hidden;
  perspective: 1000px;
}