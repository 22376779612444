.transaction-result-border-b {
  border-bottom: 2px solid black;
  min-width: 250px;
}

.transaction-result-border-b h3 {
  font-size: 1.25rem;
  font-weight: 600;
}

.resend-ticket-info-wrapper .transaction-result-wrapper p {
  font-size: 0.875rem;
  margin: 15px 0px;
  max-width: 270px;
}

.transaction-result-icon {
  position: absolute;
  left: -50px;
}

.transaction-result-btn {
  border-radius: 6px;
  background: var(--base-color-orange);
  text-decoration: none;
  color: white;
  padding: 7px 50px;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: capitalize;
}

.transaction-result-btn-container {
  margin: 60px 0px 10px 0px;
}