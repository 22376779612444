.resend-main {
  height: 60vh;
  position: relative;
}

.resend-ticket-info {
  position: absolute;
  top: -5%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99999;
  width: 65%;
}

.resend-ticket-info-wrapper {
  background: white;
  border: 1px solid var(--base-color-blue);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 6rem 3rem;
  width: 100%;
}

.resend-ticket-info-wrapper h3 {
  text-transform: uppercase;
  font-size: 1.25rem;
}

.resend-ticket-info-wrapper p {
  text-align: center;
  line-height: 1rem;
  font-size: 0.875rem;
  max-width: 400px;
  white-space: pre-wrap;
}

.resend-email-container {
  display: flex;
  align-items: flex-end;
  gap: 25px;
  width: 100%;
  margin-top: 20px;
}

.resend-email-form {
  text-align: left;
  width: 66%;
}

.resend-email-form label {
  padding-left: 10px;
  padding-bottom: 7px;
  font-size: 0.875rem;
  font-weight: 500;
  color: gray;
}

.resend-email-input {
  border-color: gray;
}

.resend-btn {
  background-color: var(--base-color-orange);
  color: white;
  padding: 4px auto;
  text-align: center;
  font-weight: bold;
  border: none;
  width: 33%;
}

.resend-btn:hover {
  background-color: var(--base-color-blue) !important;
  transition: var(--base-transition);
}

.resend-image-container {
  margin-top: -30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
}

.resend-image {
  width: 100%;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(200%);
    visibility: visible;
  }

  25% {
    transform: translateX(0);
    visibility: visible;

  }

  50% {
    transform: translateX(0);
    visibility: visible;

  }

  75% {
    visibility: visible;

    transform: translateX(0);
  }

  100% {
    visibility: visible;

    transform: translateX(-200%);
  }
}


.resend-image-bus {
  width: 65%;
  position: absolute;
  bottom: 0;
  visibility: hidden;
  right: 82px;
  animation: 10s ease-in-out 1s infinite slideInFromLeft;
}

.resend-ticket-booking-info {
  margin-top: 15px;
  display: flex;
  width: 100%;
  animation: name duration timing-function delay iteration-count direction fill-mode;
}

.resend-ticket-booking-number {
  width: 33%;
  margin-bottom: 10px;
}

.resend-ticket-booking-details {
  width: 67%;
}

.resend-ticket-booking-info .resend-ticket-booking-number h5 {
  color: gray;
  font-weight: 400;
  font-size: 0.875rem
}

.resend-ticket-booking-info .resend-ticket-booking-number h4 {
  font-weight: 700;
  font-size: 0.875rem
}

.resend-ticket-booking-info .resend-ticket-booking-details h5 {
  color: gray;
  font-weight: 400;
  font-size: 0.875rem
}

.resend-ticket-booking-details {
  display: flex;
  gap: 20px;
}

.resend-ticket-booking-details .arrival h5 {
  visibility: hidden;
}

.resend-ticket-booking-details h4 {
  font-weight: 700;
  font-size: 0.875rem;
}

.resend-ticket-booking-details h6 {
  font-weight: 400;
  font-size: 0.875rem;
}

.resend-ticket-booking-details h6:first-of-type {
  text-transform: uppercase;
}

.arrival {
  width: 50%;
}

.departure {
  width: 50%;
}

.resend-ticket-booking-number {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.bland-btn {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.icons-container {
  display: flex;
  justify-content: space-between;
}

.icons {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.resend-ticket-info .resend-ticket-info-wrapper .email-alert{
  position: absolute;
  color: red;
  width: 100%;
  text-align: start;
  font-size: 14px;
  max-width: 50%;
}

.border-alert{
  border-color: red;
}