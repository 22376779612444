@import '../../constants/constants.css';

.search-box-block {
  margin-top: -70px;
  margin-bottom: 40px;
  z-index: 5;
  position: relative;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 10px 0 10px 10px;
  overflow: hidden;
  box-shadow: var(--base-box-shadow);
  border: 1px solid #CFD0CF;
}

.search-box-block .ant-divider-inner-text {
  padding: 0;
}

.search-box-block .ant-divider-inner-text .ant-btn {
  height: 35px;
  padding: 5px 0.775rem;
  border-width: 3px;
}

.search-box-block .ant-divider:after,
.search-box-block .ant-divider:before {
  border-block-start-width: 3px !important;
}

.change-dir-btn .custom-arrow-up,
.change-dir-btn .custom-arrow-down {
  display: inline-block;
  width: 14px;
  height: 14px;
  position: relative;
  margin: 0 5px;
  transform: rotate(45deg);
}

.change-dir-btn .custom-arrow-up {
  border-top: 3px solid var(--base-color-orange);
  border-left: 3px solid var(--base-color-orange);
  top: 4px;
}

.change-dir-btn .custom-arrow-down {
  border-bottom: 3px solid var(--base-color-orange);
  border-right: 3px solid var(--base-color-orange);
  bottom: 4px;
}

.change-dir-btn:hover,
.custom-datepicker-style:hover {
  border-color: var(--base-color-orange) !important;
  transition: var(--base-transition);
}

.custom-datepicker-style.ant-picker-focused {
  border-color: var(--base-color-orange) !important;
  box-shadow: none !important;
}

.custom-datepicker-style svg path {
  fill: var(--base-color-orange);
}

.change-dir-btn:hover > span {
  transform: rotate(45deg) scale(1.2);
  transition: var(--base-transition);
}

.change-dir-btn:hover .custom-arrow-down {
  bottom: 5px;
}
.change-dir-btn:hover .custom-arrow-up  {
  top: 5px;
}

.search-box-block .ant-select {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.search-box-block .ant-select > * {
  font-size: 1.375rem;
}

.search-box-block .ant-select.ant-select-focused,
.search-box-block .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}

.search-box-block .ant-select-selector {
  padding-left: 0 !important;
}

p.custom-label-name {
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}

p.custom-label-name .label-name-style {
  max-width: 80%;
  overflow: hidden;
  overflow-wrap: nowrap;
  text-overflow: ellipsis;
  margin-right: 7px;
}

.search-box-block .ant-select-selection-search {
  left: 0 !important;
}

.search-box-block .ant-select-selection-search input {
  position: relative;
  top: -7px;
}

.ant-select-item-option-content {
  font-size: 1.375rem !important;
}

.search-box-block h5 {
  color: #898B91;
  font-size: 0.875rem;
  margin-bottom: 0px;
}

.search-box-block .search-button {
  font-size: 1.375rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--base-color-orange);
}

.search-box-block .search-button:hover {
  background-color: var(--base-color-blue) !important;
  transition: var(--base-transition);
}

.custom-datepicker-style {
  height: 41px;
  font-size: 1.375rem;
}

.custom-datepicker-style input {
  font-size: 1.25rem !important;
  text-align: center;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: var(--base-color-orange) !important;
}

.ant-picker-content .ant-picker-cell-today .ant-picker-cell-inner:before {
  border-color: var(--base-color-orange) !important;
}

.ant-picker-footer .ant-picker-today-btn {
  color: var(--base-color-orange) !important;
}

.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  display: none;
}

.ant-picker-header .ant-picker-header-view {
  order: 1;
  text-align: left;
  padding-left: 20px;
}

.ant-picker-header .ant-picker-header-prev-btn {
  order: 2;
}

.ant-picker-header .ant-picker-header-next-btn {
  order: 3;
}

.ant-picker-header .ant-picker-header-next-btn > span,
.ant-picker-header .ant-picker-header-prev-btn > span {
  padding: 5px;
  background-color: var(--base-color-orange);
}

.ant-picker-header .ant-picker-header-next-btn > span {
  border-radius: 0 5px 5px 0;
}

.ant-picker-header .ant-picker-header-prev-btn > span {
  border-radius: 5px 0 0 5px;
  margin-right: 1px;
}

.ant-picker-header .ant-picker-header-prev-btn:hover > span,
.ant-picker-header .ant-picker-header-next-btn:hover > span {
  background-color: var(--base-color-blue);
  transition: var(--base-transition);
}

.search-result-page {
  background-color: var(--base-color-blue);
  padding: 50px;
}

.search-result-page .search-box-block {
  border-radius: 10px;
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: none;
}

.search-result-item .results-items {
  border-radius: 10px;
  padding: 10px !important;
  margin-bottom: 20px !important;
  border: 1px solid #D3D3D3;
  color: #32343F;
  font-size: 1rem;
}

.result-count-style {
  width: 100%;
  text-align: right;
  padding: 5px;
  margin: 10px 0 20px;
  color: var(--base-color-blue);
}

.price-start-from {
  color: var(--base-color-blue) !important;
}

.strong-result-text {
  font-size: 1.25rem;
}

.items-date-block,
.items-direction,
.items-arrival-block {
  padding-left: 40px !important;
  position: relative;
}

.items-date-block:before,
.items-direction:before {
  content: '';
  width: 28px;
  height: 28px;
  left: -7px;
  position: absolute;
  display: inline-block;
}

.items-date-block:before {
  top: 3px;
  background: url("../../assets/img/icon_time.svg") center no-repeat;
}

.items-direction:before {
  top: 7px;
  background: url("../../assets/img/icon_bus.svg") center no-repeat;
}

.book-now-button {
  width: 100%;
  background-color: var(--base-color-blue);
  color: #ffffff;
  font-size: 1rem;
  height: inherit;
  border: none;
  padding: 10px;
  border-radius: 30px;
  font-weight: 500;
}

.book-now-button:hover {
  background-color: var(--base-color-orange);
  color: #ffffff !important;
  transition: var(--base-transition);
}

.search-result-item .anticon {
  border: 2px solid var(--base-color-blue);
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  bottom: 32px;
  left: -35px;
}
@keyframes blocked {
  0%{
    transform: translateX(5px);
  }
  25%{
    transform: translateX(-5px);
  }
  50%{
    transform: translateX(5px);
  }
  75%{
    transform: translateX(-5px);
  }
  100%{
    transform: translateX(0px);
  }
}

.block-animation{
  animation: blocked 0.3s cubic-bezier(.36, .07, .19, .97) both;
  backface-visibility: hidden;
  perspective: 1000px;
}

.block-color .ant-select-selector  .ant-select-selection-placeholder{
  color: red;
}

.search-icon{
  position: absolute;
  left: -2rem;
  top: 0.45rem
}

.search-box-block .ant-picker .ant-picker-input .ant-picker-suffix{
  position: absolute;
  right: 0;
}
.search-box-block .ant-picker .ant-picker-input .ant-picker-clear{
  position: absolute;
  right: 0;
}