@import './constants/constants.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-ticket-style {
  border: none !important;
  width: 100%;
  min-height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  color: #ffffff;
  position: relative;
  background-color: var(--base-color-blue) !important;
}

.main-ticket-style:hover {
  color: #ffffff !important;
  font-size: 1.25rem;
  transition: var(--base-transition);
}

.main-ticket-style.ant-radio-button-wrapper-checked:hover {
  color: var(--base-color-blue) !important;
}

.main-ticket-style.ant-radio-button-wrapper-checked {
  background-color: #ffffff !important;
  border: none !important;
  color: var(--base-color-blue) !important;
}

.main-ticket-style span {
  padding-left: 20px;
  z-index: 5;
}

.search-result-item {
  min-height: 40vh;
}

.common-spin-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
}

@media (min-width: 1200px) {
  #root
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1190px;
  }
}
/* @media (min-width: 992px) {
  #root
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 760px;
  }
} */