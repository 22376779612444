@import '../../../constants/constants.css';

.passenger-info-block {
  padding: 20px 20px 40px;
  border: 1px solid #D0D0D0;
  border-radius: 5px;
}

.passenger-info-block .ant-tabs-nav {
  padding-left: calc(var(--bs-gutter-x) * .5);
  padding-right: calc(var(--bs-gutter-x) * .5);
}

.passenger-info-block .ant-tabs-nav:before {
  border-bottom: none;
}

.passenger-info-block .ant-tabs-tab {
  color: var(--base-color-orange);
  background-color: white !important;
  border: 1px solid var(--base-color-orange);
  border-radius: 10px !important;
  padding: 10px 30px !important;
  font-weight: 600;
}

.passenger-info-block .ant-tabs-tab:hover {
  color: #ffffff;
  background: var(--base-color-blue) !important;
  border-color: var(--base-color-blue);
  transition: var(--base-transition) !important;
}

.passenger-info-block .ant-tabs-tab-active {
  background-color: var(--base-color-orange) !important;
}

.passenger-info-block .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff !important;
}

.passenger-info-block .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none !important;
}

.extra-luggage {
  font-weight: 600;
  line-height: 1.3rem;
  position: relative;
  margin-bottom: 0;
  padding-left: 30px;
}

.extra-luggage span {
  color: var(--base-color-orange);
}

.extra-info-block svg {
  width: 40px;
}

.extra-info-block svg .st0 {
 fill: var(--base-color-orange) !important;
}

.extra-info-block svg.email-icon-svg .st0 {
 fill: #32343F !important;
}

/*.baggage-count .ant-btn {*/
/*  border-color: #999999;*/
/*  width: 45px;*/
/*  height: 45px;*/
/*  padding: 0;*/
/*  color: #32343F !important;*/
/*}*/

/*.baggage-count .ant-btn:hover {*/
/*  border-color: var(--base-color-blue) !important;*/
/*  background: var(--base-color-blue);*/
/*  color: #ffffff !important;*/
/*  transition: var(--base-transition);*/
/*}*/

/*.baggage-count span {*/
/*  font-size: 25px;*/
/*  font-weight: 700;*/
/*  line-height: 1;*/
/*}*/

/*.baggage-count .count-bag {*/
/*  margin: 0 10px;*/
/*  width: 20px;*/
/*  text-align: center;*/
/*  color: #32343F !important;*/
/*}*/

.switch-subscribe .ant-switch {
  height: 34px;
  width: 60px;
}

.switch-subscribe .ant-switch.ant-switch-checked {
  background: var(--base-color-orange);
}

.switch-subscribe .ant-switch:hover {
  background: var(--base-color-blue) !important;
}

.switch-subscribe .ant-switch .ant-switch-handle {
  inset-inline-start: 3px;
}

.switch-subscribe .ant-switch .ant-switch-handle,
.switch-subscribe .ant-switch .ant-switch-handle:before {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.switch-subscribe .ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 33px) !important;
}

.pass-tab-tick svg{
  padding-right: 2px;
  margin-left: 2px;
  color: #ffffff;
}

.pas-tab-item{
  overflow: hidden;
}

.pass-tab-item .pass-tab-tick .anticon {
  margin-right: 0;
}

.pass-tab-item .pass-tab-tick {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #1FA79C;
  top: -1px;
  right: -1px;
  border-radius: 0 7px;
}

.passenger-info-block .ant-tabs-nav-operations {
  display: none !important;
}


.passenger-info-block.custom-pass-margin .ant-tabs-tab:nth-last-child(-n + 2) {
  margin-right: 5.5rem !important;
}

.passenger-confirm-block .premium-ticket svg .st0 {
  fill: #FBCA68 !important;
}

.passenger-confirm-block .disability-ticket svg .st0 {
  fill: #50A69C !important;
}

.passenger-confirm-block svg .st0 {
  fill: #32343F;
}