.row {
  margin: 0 !important;
  padding: 0 !important;
}

:root {
  --text-color-graphite: #222222;
  --text-color-white: #ffffff;
  /*--base-color-blue: #094267;*/
  /*--base-color-orange: #EE7D30;*/
  --base-color-blue: #1A2F58;
  --base-color-orange: #D5522A;
  --base-transition: all .1s ease;
  --base-box-shadow: 0 3px 10px -3px rgba(150,150,150,1);
  --base-box-shadow-inset: inset 3px 3px 0 0 rgba(150,150,150,1); ;
}